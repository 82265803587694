<template>
    <div>
        <section class="section section-lg">
            <div class="container">
                <div class="row row-grid justify-content-center">
                    <div class="col-lg-8 text-center">
                        <h2 class="display-3">
                            {{ item.Title }}
                        </h2>
                        <hr>
                    </div>
                    <div v-if="!error" class="col-lg-12 all-btns">
                        <p>{{ item.Description }}</p>
                        <!--<hr>-->
                        <p>
                            <a v-if="item.PdfPath" :href="item.PdfPath" download class="download-btn" @click="report(item.Title, 'pdf')">
                                <b-btn class="btn" variant="success">Скачать pdf</b-btn>
                            </a>
                        </p>
                        <p>
                            <a v-if="item.ZipPath" :href="item.ZipPath" download class="download-btn" @click="report(item.Title, 'zip')">
                                <b-btn variant="success">Скачать zip</b-btn>
                            </a>
                        </p>
                        <p>
                            <a v-if="item.SibPath" :href="item.SibPath" download class="download-btn" @click="report(item.Title, 'sib')">
                                <b-btn variant="success">Скачать sib</b-btn>
                            </a>
                        </p>
                        <p>
                            <a v-if="item.Mp3Path" :href="item.Mp3Path" download class="download-btn" @click="report(item.Title, 'mp3')">
                                <b-btn variant="success">Скачать mp3</b-btn>
                            </a>
                        </p>
                        <b-btn v-if="item.Mp3Path" variant="info" @click.prevent="playSound(item.Title, item.Mp3Path)">
                            Играть
                        </b-btn>
                        <b-btn v-if="item.Mp3Path" variant="warning text-dark" @click.prevent="pauseSound()">
                            Пауза
                        </b-btn>
                    </div>
                    <div v-if="error" class="col-lg-12">
                        {{ error_msg }}
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    name: "MaterialDisplay",
    data: () => {
        return {
            id: null,
            error: false,
            error_msg: '',
            item: '',
            audio: null
        };
    },
    beforeMount () {
        this.storeId();
        this.get_material();
    },
    methods: {
        get_material() {
            this.setHeaders();

            axios.get(this.$apiUrl + "/get_single_material",
                {
                    params: {
                        id: this.id,
                        active: true
                    }
                }
            ).then(response => {
                if (!(response.data)){
                    this.error = true;
                    this.error_msg = `Material with ID ${this.id} not found!`;
                }

                this.item = response.data;

            });
        },

        // Set the headers for any API requests
        setHeaders() {
            axios.defaults.headers.common["Content-type"] = "application/x-www-form-urlencoded";
        },

        playSound(title, sound) {
            if (sound) {

                this.$gtag.event('Play', {
                    method: 'Google',
                    event_category: 'Audio',
                    event_label: title
                });

                this.pauseSound();
                this.audio = new Audio(sound);
                this.audio.play();

            }
        },
        pauseSound() {
            if (this.audio) {
                this.audio.pause();
            }
        },
        report(title, filetype) {

            this.$gtag.event(filetype, {
                method: 'Google',
                event_category: 'Downloads',
                event_label: title
            });

        },
        storeId() {
            this.id = this.$route.params.id;

            if (isNaN(parseInt(Number(this.id)))) {
                console.info(parseInt(Number(this.id)));
                this.id = null;
                this.error = true;
                this.pageTitle = 'Unknown';
                this.error_msg = `Material number is not valid: ${this.$route.params.id}`;
            }
        }
    }
};

</script>
<style scoped>
    .all-btns {
        text-align: center;
    }
</style>
